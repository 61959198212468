





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const TermsPlugin = () => import('@/modules/term/components/TermsPlugin.vue');
const AppointmentsPlugin = () => import('@/modules/appointment/components/AppointmentsPlugin.vue');

@Component({
  components: { AppointmentsPlugin, TermsPlugin }
})
export default class EventTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
